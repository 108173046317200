import React, { useState, useEffect } from 'react';
import './App.css';
import SummaryTable from './SummaryTable';
import DetailTable from './DetailTable';
import YogaCount from './YogaCount';
import { fetchAllYogaData, fetchYogaCount } from './database';

const App = () => {
  const [yogaCount, setYogaCount] = useState(-1);
  const [allYogaActivities, setAllYogaActivities] = useState(0);

  // Fetch data from CosmosDB on component mount
  useEffect(() => {
    async function fetchRecords() {
      try {
        const items = await fetchYogaCount();
        setYogaCount(items[0].$1);
        const allYogaActivities = await fetchAllYogaData();
        setAllYogaActivities(allYogaActivities);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    fetchRecords();
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <div className="title-container">
          <img src="/yoga-animation.gif" alt="Yoga Animation" className="yoga-animation" />
          <h1 className="App-title">HabitSync</h1>
          <img src="/yoga-animation.gif" alt="Yoga Animation" className="yoga-animation" />
        </div>
        <div className="content-container">
          <YogaCount count={yogaCount} />
          <SummaryTable data={allYogaActivities} />
        </div>
        <DetailTable />
      </header>
    </div>
  );
};

export default App;
