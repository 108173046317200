import React from 'react';

const SummaryTable = () => {
  const summaryData = [
    { metric: 'Avg HR', average: 76.9, trend: '7.9%' },
    { metric: 'Max HR', average: 110.7, trend: '1.9%' },
    { metric: 'Avg RR', average: 17.6, trend: '-6.9%' },
    { metric: 'Max RR', average: 22.2, trend: '-5.0%' },
    { metric: 'Calories', average: 44.9, trend: '-1.1%' },
  ];

  return (
    <table className="table">
      <thead>
        <tr>
          <th>Metric</th>
          <th>Average</th>
          <th>Trend</th>
        </tr>
      </thead>
      <tbody>
        {summaryData.map((item, index) => (
          <tr key={index}>
            <td>{item.metric}</td>
            <td>{item.average}</td>
            <td>{item.trend}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default SummaryTable;