import React from 'react';
import './YogaCount.css';

const YogaCount = ({ count }) => {
  return (
    <div className="yoga-count">
      <p>Yoga Activities Completed in a Row</p>
      <h2>{count}</h2>
    </div>
  );
};

export default YogaCount;