import { CosmosClient } from "@azure/cosmos";

const client = new CosmosClient({
  endpoint: process.env.REACT_APP_COSMOS_DB_ENDPOINT,
  key: process.env.REACT_APP_COSMOS_DB_KEY,
  connectionPolicy: {
    requestTimeout: 10000,
  },
});

const database = client.database('HabitSync');
const container = database.container('Activity');


export async function fetchAllYogaData() {
  try {
    const querySpec = { query: 'SELECT * FROM c where c.activity_type="yoga"' };
    const response = await container.items.query(querySpec).fetchAll();
    return response.resources; // Return fetched items
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

export async function fetchYogaCount() {
    try {
      const querySpec = { query: 'SELECT COUNT(1) FROM c where c.activity_type="yoga"' };
      const response = await container.items.query(querySpec).fetchNext();
      return response.resources; // Return fetched items
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  }
  
