import React from 'react';

const DetailTable = () => {
  const list = [
    {
      id: 1,
      startTime: '2023-10-01T08:00:00Z',
      avgHR: 120,
      maxHR: 150,
      avgRR: 15,
      maxRR: 20,
      calories: 300
    },
    {
      id: 2,
      startTime: '2023-10-02T09:00:00Z',
      avgHR: 110,
      maxHR: 140,
      avgRR: 14,
      maxRR: 18,
      calories: 250
    }
  ];

  return (
    <table className="table">
      <thead>
        <tr>
          <th>ID</th>
          <th>Start Time</th>
          <th>Avg HR</th>
          <th>Max HR</th>
          <th>Avg RR</th>
          <th>Max RR</th>
          <th>Calories</th>
        </tr>
      </thead>
      <tbody>
        {list.map(item => (
          <tr key={item.id}>
            <td>{item.id}</td>
            <td>{item.startTime}</td>
            <td>{item.avgHR}</td>
            <td>{item.maxHR}</td>
            <td>{item.avgRR}</td>
            <td>{item.maxRR}</td>
            <td>{item.calories}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default DetailTable;